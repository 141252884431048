var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("車両予約")]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "a-form",
                  { attrs: { layout: "inline" } },
                  [
                    _vm._v("\n            対象支店：\n            "),
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "30%", "margin-right": "20px" },
                        attrs: {
                          "show-search": "",
                          "filter-option": _vm.filterOption
                        },
                        model: {
                          value: _vm.branchId,
                          callback: function($$v) {
                            _vm.branchId = $$v
                          },
                          expression: "branchId"
                        }
                      },
                      _vm._l(_vm.branches, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.name) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v("\n            対象日：\n            "),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "3px" },
                        on: {
                          click: function($event) {
                            return _vm.yesterday()
                          }
                        }
                      },
                      [_vm._v("前日")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "3px" },
                        on: {
                          click: function($event) {
                            return _vm.today()
                          }
                        }
                      },
                      [_vm._v("今日")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "7px" },
                        on: {
                          click: function($event) {
                            return _vm.tomorrow()
                          }
                        }
                      },
                      [_vm._v("翌日")]
                    ),
                    _c(
                      "a-config-provider",
                      { attrs: { locale: _vm.locale } },
                      [
                        _c("a-date-picker", {
                          attrs: {
                            placeholder: "対象日",
                            format: "YYYY/MM/DD"
                          },
                          model: {
                            value: _vm.targetDate,
                            callback: function($$v) {
                              _vm.targetDate = $$v
                            },
                            expression: "targetDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.refreshList }
                      },
                      [_vm._v("\n              取得\n            ")]
                    )
                  ],
                  1
                ),
                _c("hr", { staticClass: "my-4" }),
                _c("div", { staticClass: "clearfix" }, [
                  _c("div", { staticClass: "float-left" }, [
                    _c("h4", [
                      _vm._v(_vm._s(_vm._f("momentDate")(_vm.gotDate)))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c("a-form", { attrs: { layout: "inline" } }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("フィルター")
                        ]),
                        _c("span", { staticClass: "ml-4" }, [_vm._v("車両：")]),
                        _c(
                          "div",
                          { staticClass: "d-inline-block" },
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  mode: "multiple",
                                  placeholder: "車両",
                                  value: _vm.filteredVehicleIds,
                                  "filter-option": _vm.filterOption
                                },
                                on: { change: _vm.onChangeVehiclesFilter }
                              },
                              _vm._l(_vm.branchesWithVehicles, function(
                                branch
                              ) {
                                return _c(
                                  "a-select-opt-group",
                                  {
                                    key: branch.id,
                                    attrs: { label: branch.name }
                                  },
                                  _vm._l(branch.vehicles, function(vehicle) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: vehicle.id,
                                        attrs: { value: vehicle.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(vehicle.name) +
                                            "\n                      "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ]),
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "a-collapse",
                              {
                                model: {
                                  value: _vm.myReservationsCollapseKey,
                                  callback: function($$v) {
                                    _vm.myReservationsCollapseKey = $$v
                                  },
                                  expression: "myReservationsCollapseKey"
                                }
                              },
                              [
                                _c(
                                  "a-collapse-panel",
                                  {
                                    key: "1",
                                    attrs: {
                                      header:
                                        _vm.myUserData.name +
                                        " 様が予約している車両一覧"
                                    }
                                  },
                                  [
                                    _c(
                                      "table",
                                      [
                                        _vm._l(
                                          _vm.sortedMyReservations,
                                          function(reservation) {
                                            return _c(
                                              "tr",
                                              { key: reservation.id },
                                              [
                                                _c("td", [_vm._v("・")]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      reservation.vehicle_name
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "td",
                                                  { staticClass: "pl-4" },
                                                  [
                                                    _vm._v(
                                                      ": " +
                                                        _vm._s(
                                                          _vm.formatTerm(
                                                            reservation
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _vm.myReservations.length === 0
                                          ? _c("tr", [
                                              _c("td", [_vm._v("・")]),
                                              _c("td", [_vm._v("なし")])
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              margin: "10px 0 2px",
                              "font-size": "1.2rem",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              "カレンダー内をクリックすると予約が作成できます。"
                            )
                          ]
                        ),
                        _c("FullCalendar", {
                          ref: "fullCalendar",
                          attrs: { options: _vm.calendarOptions },
                          scopedSlots: _vm._u([
                            {
                              key: "eventContent",
                              fn: function(arg) {
                                return [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(_vm._s(arg.event.title))
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "white-space": "nowrap",
                                            overflow: "hidden"
                                          }
                                        },
                                        [_vm._v(_vm._s(arg.event.title))]
                                      )
                                    ],
                                    2
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.modalTitle, visible: _vm.modalVisible },
          on: { cancel: _vm.handleCancelModal }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "reservationFormRef",
                  attrs: {
                    model: _vm.reservationForm,
                    rules: _vm.reservationFormRules
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "開始日時", prop: "date_time_start" } },
                    [
                      _c(
                        "a-config-provider",
                        { attrs: { locale: _vm.locale } },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              disabled: _vm.isAllDay,
                              format: "YYYY/MM/DD"
                            },
                            model: {
                              value: _vm.reservationForm.date_start,
                              callback: function($$v) {
                                _vm.$set(_vm.reservationForm, "date_start", $$v)
                              },
                              expression: "reservationForm.date_start"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-config-provider",
                        { attrs: { locale: _vm.locale } },
                        [
                          _c("a-time-picker", {
                            staticClass: "ml-1",
                            attrs: { format: "HH:mm", disabled: _vm.isAllDay },
                            model: {
                              value: _vm.reservationForm.time_start,
                              callback: function($$v) {
                                _vm.$set(_vm.reservationForm, "time_start", $$v)
                              },
                              expression: "reservationForm.time_start"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "終了日時", prop: "date_time_end" } },
                    [
                      _c(
                        "a-config-provider",
                        { attrs: { locale: _vm.locale } },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              disabled: _vm.isAllDay,
                              format: "YYYY/MM/DD"
                            },
                            model: {
                              value: _vm.reservationForm.date_end,
                              callback: function($$v) {
                                _vm.$set(_vm.reservationForm, "date_end", $$v)
                              },
                              expression: "reservationForm.date_end"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-config-provider",
                        { attrs: { locale: _vm.locale } },
                        [
                          _c("a-time-picker", {
                            staticClass: "ml-1",
                            attrs: { format: "HH:mm", disabled: _vm.isAllDay },
                            model: {
                              value: _vm.reservationForm.time_end,
                              callback: function($$v) {
                                _vm.$set(_vm.reservationForm, "time_end", $$v)
                              },
                              expression: "reservationForm.time_end"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      staticClass: "mt-2 mb-4",
                      on: { change: _vm.onChangeAllDay },
                      model: {
                        value: _vm.isAllDay,
                        callback: function($$v) {
                          _vm.isAllDay = $$v
                        },
                        expression: "isAllDay"
                      }
                    },
                    [_vm._v("終日")]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "運転者", prop: "user_id" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            "filter-option": _vm.filterOption
                          },
                          model: {
                            value: _vm.reservationForm.user_id,
                            callback: function($$v) {
                              _vm.$set(_vm.reservationForm, "user_id", $$v)
                            },
                            expression: "reservationForm.user_id"
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { key: 0, attrs: { value: 0 } },
                            [_vm._v("\n              未選択\n            ")]
                          ),
                          _vm._l(_vm.drivers, function(item) {
                            return _c(
                              "a-select-option",
                              { key: item.id, attrs: { value: item.id } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.name) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "車両", prop: "vehicle_id" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            "filter-option": _vm.filterOption,
                            disabled: _vm.isUpdate
                          },
                          model: {
                            value: _vm.reservationForm.vehicle_id,
                            callback: function($$v) {
                              _vm.$set(_vm.reservationForm, "vehicle_id", $$v)
                            },
                            expression: "reservationForm.vehicle_id"
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { key: 0, attrs: { value: 0 } },
                            [_vm._v("\n              未選択\n            ")]
                          ),
                          _vm._l(_vm.branchesWithVehicles, function(branch) {
                            return _c(
                              "a-select-opt-group",
                              { key: branch.id, attrs: { label: branch.name } },
                              _vm._l(branch.vehicles, function(vehicle) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: vehicle.id,
                                    attrs: { value: vehicle.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(vehicle.name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    { key: "back", on: { click: _vm.handleCancelModal } },
                    [_vm._v("\n            戻る\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      class: _vm.$style.filledBtn,
                      attrs: {
                        type: "primary",
                        loading: _vm.reservationConfirmLoading
                      },
                      on: { click: _vm.handleOkReservation }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.isUpdate ? "更新" : "車両予約") +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _vm.isUpdate
                    ? _c(
                        "a-button",
                        {
                          class: _vm.$style.filledBtn,
                          staticStyle: {
                            "background-color": "#EF5350 !important"
                          },
                          attrs: { loading: _vm.reservationConfirmLoading },
                          on: { click: _vm.handleDeleteReservation }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }