<template>
  <div>
    <h1 :class="$style.pageTitle">車両予約</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <a-form layout="inline">
              対象支店：
              <a-select
                show-search
                v-model="branchId"
                style="width: 30%; margin-right: 20px;"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in branches"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              対象日：
              <a-button @click="yesterday()" style="margin-right: 3px;">前日</a-button>
              <a-button @click="today()" style="margin-right: 3px;">今日</a-button>
              <a-button @click="tomorrow()" style="margin-right: 7px;">翌日</a-button>
              <a-config-provider :locale="locale">
                <a-date-picker placeholder="対象日" v-model="targetDate" format="YYYY/MM/DD" />
              </a-config-provider>
              <a-button
                type="primary" :loading="loading" @click="refreshList"
                style="margin-left: 10px;" :class="$style.filledBtn"
              >
                取得
              </a-button>
            </a-form>
            <hr class="my-4">

            <div class="clearfix">
              <div class="float-left">
                <h4>{{ gotDate | momentDate }}</h4>
              </div>
              <div class="float-right">
                <a-form layout="inline">
                  <span class="font-weight-bold">フィルター</span>
                  <span class="ml-4">車両：</span>
                  <div class="d-inline-block">
                    <a-select
                      mode="multiple"
                      placeholder="車両"
                      :value="filteredVehicleIds"
                      :filter-option="filterOption"
                      @change="onChangeVehiclesFilter"
                      style="width: 300px"
                    >
                      <a-select-opt-group v-for="branch in branchesWithVehicles" :label="branch.name" :key="branch.id">
                        <a-select-option
                          v-for="vehicle in branch.vehicles"
                          :key="vehicle.id"
                          :value="vehicle.id"
                        >
                          {{ vehicle.name }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </div>
                </a-form>
              </div>
            </div>

            <div v-if="loading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-else class="mt-4">
              <div class="mb-2">
                <a-collapse v-model="myReservationsCollapseKey">
                  <a-collapse-panel key="1" :header="`${myUserData.name} 様が予約している車両一覧`">
                    <table >
                      <tr v-for="reservation in sortedMyReservations" :key="reservation.id">
                        <td>・</td>
                        <td>{{ reservation.vehicle_name }}</td>
                        <td class="pl-4">: {{ formatTerm(reservation) }}</td>
                      </tr>
                      <tr v-if="myReservations.length === 0">
                        <td>・</td>
                        <td>なし</td>
                      </tr>
                    </table>
                  </a-collapse-panel>
                </a-collapse>
              </div>
              <p style="margin: 10px 0 2px; font-size: 1.2rem; font-weight: bold;">カレンダー内をクリックすると予約が作成できます。</p>
              <FullCalendar :options="calendarOptions" ref="fullCalendar">
                <template #eventContent="arg">
                  <a-tooltip>
                    <template slot="title">{{ arg.event.title }}</template>
                    <div style="white-space: nowrap; overflow: hidden;">{{ arg.event.title }}</div>
                  </a-tooltip>
                </template>
              </FullCalendar>
            </div>
          </div><!-- /.card-body -->
        </div>
      </div>
    </div>

    <!-- 作成/編集モーダル -->
    <a-modal
      :title="modalTitle"
      :visible="modalVisible"
      @cancel="handleCancelModal"
    >
      <div>
        <a-form-model
          :model="reservationForm"
          ref="reservationFormRef"
          :rules="reservationFormRules"
        >
          <a-form-model-item label="開始日時" prop="date_time_start">
            <a-config-provider :locale="locale">
              <a-date-picker v-model="reservationForm.date_start" :disabled="isAllDay" format="YYYY/MM/DD" />
            </a-config-provider>
            <a-config-provider :locale="locale">
              <a-time-picker v-model="reservationForm.time_start" format="HH:mm" class="ml-1" :disabled="isAllDay" />
            </a-config-provider>
          </a-form-model-item>

          <a-form-model-item label="終了日時" prop="date_time_end">
            <a-config-provider :locale="locale">
              <a-date-picker v-model="reservationForm.date_end" :disabled="isAllDay" format="YYYY/MM/DD" />
            </a-config-provider>
            <a-config-provider :locale="locale">
              <a-time-picker v-model="reservationForm.time_end" format="HH:mm" class="ml-1" :disabled="isAllDay" />
            </a-config-provider>
          </a-form-model-item>

          <a-checkbox v-model="isAllDay" class="mt-2 mb-4" @change="onChangeAllDay">終日</a-checkbox>

          <a-form-model-item label="運転者" prop="user_id">
            <a-select
              show-search
              v-model="reservationForm.user_id"
              :filter-option="filterOption"
            >
              <a-select-option :key="0" :value="0">
                未選択
              </a-select-option>
              <a-select-option
                v-for="item in drivers"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="車両" prop="vehicle_id">
            <a-select
              show-search
              v-model="reservationForm.vehicle_id"
              :filter-option="filterOption"
              :disabled="isUpdate"
            >
              <a-select-option :key="0" :value="0">
                未選択
              </a-select-option>
              <a-select-opt-group v-for="branch in branchesWithVehicles" :label="branch.name" :key="branch.id">
                <a-select-option
                  v-for="vehicle in branch.vehicles"
                  :key="vehicle.id"
                  :value="vehicle.id"
                >
                  {{ vehicle.name }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelModal">
              戻る
            </a-button>
            <a-button key="submit" type="primary" :loading="reservationConfirmLoading" @click="handleOkReservation" :class="$style.filledBtn">
              {{ isUpdate ? '更新' : '車両予約' }}
            </a-button>
          </div>
          <div class="float-left">
            <a-button
              v-if="isUpdate"
              :class="$style.filledBtn" style="background-color: #EF5350 !important;"
              :loading="reservationConfirmLoading"
              @click="handleDeleteReservation">
              削除
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
  @import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      locale: jaJa,
      targetDate: moment(), // 対象日 (検索条件)
      gotDate: moment(), // 一覧データを取得した日
      loading: true,

      myUserData: {},
      branchId: null, // 選択された支店ID
      branchIdSelected: null, // 選択された支店ID (確定)
      branches: [], // 対象支店リスト
      drivers: [], // 対象ユーザーリスト
      vehicles: [], // 対象車両リスト
      filteredVehicleIds: [], // フィルターで選択された車両ID
      myReservations: [], // 自分の当日の車両予約リスト
      myReservationsCollapseKey: ['1'], // 自分の予約一覧を初期表示で展開する

      targetReservationId: 0, // 更新/削除対象の車両予約ID
      modalTitle: '予約入力内容',
      modalVisible: false,
      reservationConfirmLoading: false,
      reservationForm: {
        date_start: moment(),
        time_start: moment(),
        date_time_start: moment(),
        date_end: moment(),
        time_end: moment(),
        date_time_end: moment(),
        user_id: 0,
        vehicle_id: 0,
      },
      isAllDay: false, // 終日フラグ

      calendarOptions: {
        schedulerLicenseKey: '0258754835-fcs-1683696578', // ライセンスキー
        plugins: [
          resourceTimelinePlugin,
          interactionPlugin,
        ],
        initialView: 'resourceTimelineDay',
        headerToolbar: null,
        locale: 'ja',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
        },
        resourceGroupField: 'group',
        resourceAreaHeaderContent: '支店名',
        resourceAreaWidth: this.$store.getters.state.isMobileView ? '35%' : '20%',
        height: 'auto',
        resources: [],
        events: [],
        dateClick: this.onClickTimeline,
        eventClick: this.onClickEvent,
      },
    }
  },
  computed: {
    gotDateHyphen() {
      return this.gotDate.format('YYYY-MM-DD')
    },
    // 支店管理者以上ならばtrue
    isMaster() {
      return this.myUserData.role < 3
    },
    // 更新時はtrue
    isUpdate() {
      return !!this.targetReservationId
    },
    // 支店でグルーピングした車両リスト
    branchesWithVehicles() {
      const branches = []
      for (const vehicle of this.vehicles) {
        let branch = branches.find(b => b.id === vehicle.branch_id)
        if (!branch) {
          branch = {
            id: vehicle.branch_id,
            name: vehicle.branch_name,
            vehicles: [],
          }
          branches.push(branch)
        }
        branch.vehicles.push(vehicle)
      }
      return branches
    },
    // 作成/編集モーダルのバリデーションルール
    reservationFormRules() {
      const requiredRule = { required: true, message: '必須項目です。', trigger: 'change' }
      const requiredSelectRule = {
        required: true,
        type: 'number',
        min: 1,
        message: '必須項目です。',
        trigger: 'blur',
      }

      return {
        date_time_start: [requiredRule],
        date_time_end: [requiredRule],
        user_id: [requiredSelectRule],
        vehicle_id: [requiredSelectRule],
      }
    },
    sortedMyReservations() {
      return [...this.myReservations]
        .filter(reservation => {
          const start = moment(reservation.date_time_start)
          const end = moment(reservation.date_time_end)
          const targetStart = this.targetDate.clone().startOf('day')
          const targetEnd = targetStart.clone().add(1, 'd')
          return targetStart.isBefore(end) && start.isBefore(targetEnd)
        })
        .sort((a, b) => {
          return moment(a.date_time_start).toDate().getTime() - moment(b.date_time_start).toDate().getTime()
        })
    },
  },
  watch: {
    branches: function(val) {
      if (val.length >= 1) this.refreshList()
    },
    branchIdSelected: function(id) {
      Vue.prototype.$api.send('get', `vehicles/list/${id}/open`)
        .then(response => {
          this.vehicles = response.map((item) => {
            if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
            return item
          })
          this.filterVehicles()
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車両の取得に失敗しました。',
          })
        })
    },
  },
  created() {
    Vue.prototype.$api.send('get', 'user')
      .then(myResponse => {
        this.myUserData = myResponse
        this.concatVehicleNameAndNo = myResponse.concat_vehicle_name_and_no
        if (myResponse.role < 3) {
          Vue.prototype.$api.send('get', 'users').then(response => {
            this.drivers = response
          })
            .catch(error => {
              this.$notification['error']({
                message: error.status + ': ユーザーの取得に失敗しました。',
              })
            })
        } else {
          this.drivers = [myResponse]
        }

        Vue.prototype.$api.send('get', 'user/branches')
          .then(response => {
            // 先頭に「全社」を追加
            this.branches = [{ id: 0, name: '全社' }].concat(response)
            this.branchId = Number(this.$route.query.branchId) || this.branches[0].id
          })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': 支店の取得に失敗しました。',
            })
          })
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
      })
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('YYYY/MM/DD')
    },
  },
  methods: {
    filterOption(input, option) {
      let text = option.componentOptions.children[0].text
      if (text == null) {
        // opt-groupの場合
        text = option.componentOptions.children[0].componentOptions.children[0].text
      }
      return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    yesterday() { this.targetDate = moment(this.targetDate).subtract(1, 'days') },
    today() { this.targetDate = moment() },
    tomorrow() { this.targetDate = moment(this.targetDate).add(1, 'days') },
    // 車両のフィルタリング
    filterVehicles() {
      this.calendarOptions.resources = this.vehicles
        .filter(vehicle => {
          if (this.filteredVehicleIds.length) {
            return this.filteredVehicleIds.find(id => id === vehicle.id)
          } else {
            return true
          }
        })
        .map(vehicle => ({
          id: vehicle.id,
          group: vehicle.branch_name,
          title: vehicle.name,
        }))
    },
    // 車両フィルタ変更時
    onChangeVehiclesFilter(ids) {
      this.filteredVehicleIds = ids
      this.filterVehicles()
    },
    // 一覧データ取得 (共通)
    refreshList() {
      this.loading = true
      this.branchIdSelected = this.branchId
      this.gotDate = this.targetDate.clone()
      const bodyData = { date: this.gotDateHyphen }

      Promise.all([
        Vue.prototype.$api.send('get', 'vehicle_reservations', bodyData),
        Vue.prototype.$api.send('get', 'vehicle_reservations/user', bodyData),
      ])
        .then(responses => {
          if (this.branchIdSelected !== 0) this.calendarOptions.resourcesInitiallyExpanded = true
          else this.calendarOptions.resourcesInitiallyExpanded = false
          this.calendarOptions.initialDate = this.gotDateHyphen
          this.calendarOptions.events = responses[0].map(this.reservationToEvent)
          this.myReservations = responses[1]
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 運行前点検の取得に失敗しました。',
          })
          this.inspectionDataList = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 作成/編集モーダルのキャンセルボタンクリック時
    handleCancelModal() { this.modalVisible = false },
    // FullCalendarのタイムラインクリック時
    onClickTimeline(info) {
      this.isAllDay = false
      this.targetReservationId = 0
      const thisDate = moment(info.date)
      this.reservationForm.date_start = thisDate
      this.reservationForm.time_start = thisDate.clone()
      this.reservationForm.date_end = thisDate.clone().add(1, 'h')
      this.reservationForm.time_end = thisDate.clone().add(1, 'h')
      this.reservationForm.user_id = this.myUserData.id
      this.reservationForm.vehicle_id = Number(info.resource.id)
      this.clearValidate()
      this.modalVisible = true
    },
    // FullCalendarのイベントクリック時
    onClickEvent(info) {
      this.targetReservationId = Number(info.event.id)
      const event = this.calendarOptions.events.find(e => e.id === this.targetReservationId)
      const reservation = event.extendedProps.reservation
      if (reservation.user_id !== this.myUserData.id && !this.isMaster) return

      this.isAllDay = false
      this.reservationForm.date_start = moment(reservation.date_time_start)
      this.reservationForm.time_start = moment(reservation.date_time_start)
      this.reservationForm.date_end = moment(reservation.date_time_end)
      this.reservationForm.time_end = moment(reservation.date_time_end)
      this.isAllDay = this.reservationForm.time_start.format('HH:mm:ss') === '00:00:00' &&
        this.reservationForm.time_end.format('HH:mm:ss') === '00:00:00'
      this.reservationForm.user_id = reservation.user_id
      this.reservationForm.vehicle_id = reservation.vehicle_id
      this.clearValidate()
      this.modalVisible = true
    },
    // 編集モーダルの削除ボタンクリック時
    handleDeleteReservation() {
      this.reservationConfirmLoading = true
      Vue.prototype.$api.send('delete', 'vehicle_reservations', { id: this.targetReservationId })
        .then(_response => {
          this.$notification['success']({
            message: '車両予約を削除しました。',
          })
          this.calendarOptions.events = this.calendarOptions.events.filter(e => e.id !== this.targetReservationId)
          this.myReservations = this.myReservations.filter(r => r.id !== this.targetReservationId)
          this.modalVisible = false
        })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data) {
            for (const key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          console.error(error)
        })
        .finally(() => {
          this.reservationConfirmLoading = false
        })
    },
    // 作成/編集モーダルの作成/更新ボタンクリック時
    handleOkReservation() {
      const mergeDateTime = (date, time) => {
        if (date && time) {
          return moment(date.format('YYYY-MM-DD') + ' ' + time.format('HH:mm'))
        }
        return null
      }
      const formatDateTime = (m) => m.format('YYYY-MM-DD HH:mm:ss')

      this.reservationForm.date_time_start =
        mergeDateTime(this.reservationForm.date_start, this.reservationForm.time_start)
      this.reservationForm.date_time_end =
        mergeDateTime(this.reservationForm.date_end, this.reservationForm.time_end)

      this.clearValidate()
      this.$refs.reservationFormRef.validate(valid => {
        if (!valid) return

        this.reservationConfirmLoading = true
        const bodyData = {
          user_id: this.reservationForm.user_id,
          date_time_start: formatDateTime(this.reservationForm.date_time_start),
          date_time_end: formatDateTime(this.reservationForm.date_time_end),
        }

        if (this.isUpdate) {
          bodyData.id = this.targetReservationId
        } else {
          bodyData.vehicle_id = this.reservationForm.vehicle_id
        }

        Vue.prototype.$api.send(
          this.isUpdate ? 'put' : 'post',
          'vehicle_reservations',
          bodyData,
        )
          .then(response => {
            this.$notification['success']({
              message: `車両予約を${this.isUpdate ? '更新' : '作成'}しました。`,
            })

            this.calendarOptions.events = this.calendarOptions.events.filter(e => e.id !== response.id)
            this.calendarOptions.events.push(this.reservationToEvent(response))
            this.myReservations = this.myReservations.filter(r => r.id !== this.targetReservationId)
            if (response.user_id === this.myUserData.id) {
              this.myReservations.push(response)
            }
            this.modalVisible = false
          })
          .catch(error => {
            if (error.status === 409 || error.status === 403 || error.status === 400) {
              this.$notification['error']({
                message: error.data.data,
              })
            } else if (error.data && error.data.errors) {
              for (const key in error.data.errors) {
                this.$refs[key].validateState = 'error'
                this.$refs[key].validateMessage = error.data.errors[key]
              }
            } else {
              this.$notification['error']({
                message: `車両予約の${this.isUpdate ? '更新' : '作成'}に失敗しました。`,
              })
            }
            console.error(error)
          })
          .finally(() => {
            this.reservationConfirmLoading = false
          })
      })
    },
    // 作成/編集モーダルのバリデーション結果をリセットする
    clearValidate(nameList) {
      try {
        this.$refs.reservationFormRef.clearValidate(nameList)
      } catch (_error) {
        // ignore
      }
    },
    // 作成/編集モーダル上の終日チェックボックス変更時
    onChangeAllDay() {
      if (!this.isAllDay) return

      if (!this.reservationForm.date_start) {
        this.reservationForm.date_start = moment(this.gotDateHyphen)
      }
      this.reservationForm.time_start = this.reservationForm.date_start.clone().startOf('day')
      this.reservationForm.date_end = this.reservationForm.date_start.clone().add(1, 'd')
      this.reservationForm.time_end = this.reservationForm.time_start.clone().add(1, 'd')
      this.clearValidate(['date_time_start', 'date_time_end'])
    },
    // APIレスポンスの車両予約データをイベントデータに変換する
    reservationToEvent(reservation) {
      const bgColor = reservation.user_id === this.myUserData.id ? '#ffa500' : '#3cb371'
      return {
        id: reservation.id,
        resourceId: reservation.vehicle_id,
        title: reservation.user_name,
        start: moment(reservation.date_time_start).format(),
        end: moment(reservation.date_time_end).format(),
        backgroundColor: `${bgColor}80`,
        textColor: '#333',
        borderColor: bgColor,
        extendedProps: {
          reservation,
        },
      }
    },
    // 予約期間
    formatTerm(reservation) {
      const start = moment(reservation.date_time_start)
      const end = moment(reservation.date_time_end)
      const startTime = start.format('HH:mm')
      const endTime = end.format('HH:mm')

      if (endTime === '00:00' && startTime === '00:00') {
        return '終日'
      }

      const startDate = start.isSame(this.gotDate, 'day') ? '' : start.format('M/d ')
      const endDate = end.isSame(this.gotDate, 'day') ? '' : end.format('M/d ')
      return `${startDate}${startTime} 〜 ${endDate}${endTime}`
    },
  },
}
</script>
